html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  /* height: 100vh;
  overflow-x: hidden;
  overflow: hidden; */
}
/* 
*,
*:before,
*:after {
  box-sizing: inherit;
} */

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
}

h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
}

main,
li {
  display: block;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
}

strong {
  font-weight: bold;
}

a,
button {
  color: inherit;
  transition: .3s;
}

a {
  text-decoration: none;
}

button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

:focus {
  outline: 0;
}

/* mairjo ovo je vazno  ==== images removed for new image creation marijo marijo marijo mairjo */
img:not(.react-parallax-bgimage) {
  max-width: 100%;
  /* height: auto; */
  border: 0;
} 

/* .react-parallax:after {
content: ' ';
background-color: black;
position: absolute;
height: 100vh;
width: 100vw;
display: block;
top: 0;
z-index: -1;
}
.react-parallax img{
  visibility: hidden;
} */