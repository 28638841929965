.you-won-dialog {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 200px;
  z-index: 2;
  > div {
    // width: 105px;
    background-color: white;
    padding: 16px;
    // opacity: 0.8;
    // -webkit-transition: all 0.05s ease-out;
    // -moz-transition: all 0.05s ease-out;
    // -o-transition: all 0.05s ease-out;
    // transition: all 0.05s ease-out;

    transition: all 0.05s ease-out;
    box-shadow: 0px 2px 7px 0px #333;
    border-radius: 4px;
  //   .slider-with-marker .MuiSlider-track {
  //     background-size: 105px 6px;
  //     -webkit-transition: all 0.05s ease-out;
  //     -moz-transition: all 0.05s ease-out;
  //     -o-transition: all 0.05s ease-out;
  //     transition: all 0.05s ease-out;
  width: 160px;
  .slider-with-marker .MuiSlider-track {
    background-size: 160px 6px;
}
  // }
    // &:hover {
    //   width: 160px;
    //   .slider-with-marker .MuiSlider-track {
    //     background-size: 160px 6px;
    // }
    // }
    > h1 {
      color: #0be126;
      opacity: 0.5;
      font-size: 23px;
      font-weight: 600;
      letter-spacing: 1.1px;
    }
  }
}
