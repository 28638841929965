html[dir="rtl"] {
  //###### menu
  .menu-container .container {
    text-align: left;
  }

  .user-name {
    margin: 0 15px 10px 58px;
    text-align: left;
    a {
      border-right: none;
      border-left: 1px solid #d6d6d6;
    }
  }
  .language-toggle {
    margin-left: 0;
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    .menu-container ul.menu-footer {
      text-align: right;
      padding-top: 0;
    }
  }

  //###### page title
  .page-title {
    .follow-component {
      float: left;
      .follow-button {
        margin: 0 0 0 12px;
      }
    }
    .feed-title {
      float: right;
      padding-left: 10px;
      @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 10px;
      }
    }
    .edit-playlist-container {
      float: left;
      margin-right: 0;
      margin-left: 7px;
    }
  }
  .feed-desc {
    text-align: right;
  }

  //#### collection
  .slide-container .infinite-scroll-component {
    padding-left: 0;
    padding-right: 10px;
  }

  //##### feed
  .feed-container .infinite-scroll-component {
    text-align: right;
    .feed-item-header .video-attrib-img-col {
      float: right;
    }
    .feed-item-footer .statistics {
      padding: 0 10px 0 5px;
      .views {
        text-align: right;
        float: right;
      }
      .feed-menu-button-container {
        float: left;
      }
      .select-reactions > div:after {
        margin-left: 0;
        margin-right: 7%;
      }
    }
  }

  //###### modals
  .add-in-modal {
    ul li button {
      text-align: right;
      .float-right{
      float: left;
      }
    }
    .create-new .di {
      padding: 0 0 0 9px;
    }
  }
  .edit-playlist-modal .modal-content ul li {
    text-align: right;
  }
  .language-modal {
    .language-header{
      text-align: center;
    }
    .language-list .languages {
      text-align: right;
    }
  }

  //###### profile
  .register-form {
    label {
      text-align: right;
    }
    input {
      background-position: left;
    }
    select {
      background-position: left;
      padding: 10px 10px 10px 20px;
    }
    input[type="checkbox"] {
      float: right;
    }
    .checkbox-group label input[type="checkbox"]{
      margin-left: 0;
      margin-right: -25px;
    }
    .checkbox-group label {
      padding: 2px 25px 5px 10px;
    }
    .radio-group {
      text-align: right;
    }
  }

  //#### warning notification
  .warning-notification {
    .container {
      text-align: right;
    }
  }
}
