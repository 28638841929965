/* Moved here because the first load of the app was ugly*/

@import "./variables.scss";

.header-container {
  height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1003;
  background-color: transparent;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // position: sticky;
  // position: -webkit-sticky;
  @include transition;

  @media (max-width: 768px) {
    height: 54px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100%;
    /// header logo
    //new logo
    .header-logo {
      cursor: pointer;
      display: inline-block;
      padding-left: 15px;
      width: 102px;
      font-family: "Major Mono Display", monospace;
      position: relative;
      width: 84px;
      height: 20px;
      
      -webkit-filter: invert(100%);
      filter: invert(100%);

      > span {
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        display: inline-block;
        position: absolute;
        &:nth-child(6) {
          left: 70px;
        }
        &:nth-child(5) {
          left: 56px;
        }
        &:nth-child(4) {
          left: 42px;
        }
        &:nth-child(3) {
          left: 28px;
        }
        &:nth-child(2) {
          left: 14px;
        }
        &:nth-child(1) {
          left: 0;
        }
      }
      &:hover {
        > span {
          &:nth-child(2),
          &:nth-child(4) {
            -moz-transform: scale(-1, 1);
            -webkit-transform: scale(-1, 1);
            -o-transform: scale(-1, 1);
            -ms-transform: scale(-1, 1);
            transform: scale(-1, 1);
          }
        }
      }
    }

    //list menu
    .inside-container {
      width: calc(100% - 204px);
      opacity: 1;
      @include transition;

      &.search-opened {
        opacity: 0;
      }

      @media (max-width: 768px) {
        display: none;
      }

      .menu-list {
        display: flex;
        justify-content: space-evenly;
        font-size: 18px;
        min-width: 54px;
        display: flex;
        align-items: center;
        width: 100%;

        @media (max-width: 768px) {
          padding-top: 10px;
          font-size: 16px;
        }
        > li {
          text-transform: uppercase;
          font-family: "Open Sans Condensed", sans-serif;

          a .material-icons {
            color: $base-1;
          }
          a.active {
            // color: $base-1;
          }
        }
      }
    }

    //menu icons
    .menu-icons {
      display: flex;
      align-items: center;
      width: 102px;
      justify-content: space-evenly;
      display: flex;
      // align-items: end;
      align-items: flex-end;
      width: 132px;
      justify-content: space-evenly;
      > li {
        // padding-left: 10px;
        padding-left: 10px;
        position: relative;
        @media (max-width: 768px) {
          position: unset;
        }
      }

      @media (max-width: 768px) {
        width: calc(100% - 120px);
      }
    }
  }
}

.header-container.collapsed {
  height: 59px;
  // background-color: #fff;
  // box-shadow: 0 -31px 20px 24px rgba(115, 115, 115, 0.75);
  @media (max-width: 768px) {
    height: 54px;
  }

  .container {
    .header-logo {
      > span {
        &.logo-alchemy {
          opacity: 0;
        }
      }
      img {
        width: 50px;
      }
    }

    //list menu
    .inside-container {
      .menu-list {
        // padding-top: 0;
        // margin-top: 0;
      }
    }

    //menu icons
  }
}

.portfolio-page-header {
  .header-container {
    .container {
      .header-logo {
        > span {
          // -webkit-transition: all 1s ease-out;
          // -moz-transition: all 1s ease-out;
          // -o-transition: all 1s ease-out;
          // transition: all 1s ease-out;
          &:nth-child(1) {
            left: 70px;
          }
          &:nth-child(2) {
            left: 56px;
          }
          &:nth-child(3) {
            left: 42px;
          }
          &:nth-child(4) {
            left: 28px;
          }
          &:nth-child(5) {
            left: 14px;
          }
          &:nth-child(6) {
            left: 0;
          }
        }
      }
    }
  }
}
