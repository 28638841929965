$border_color: #888;
$border_color_two: #888;
$greyed_out: #f2f1f1;
$error_color: #f6e4e4;
$border_inset: #ececec;
$selected_color: #bbbbff;
$inset_color: #aaa;
$background_color: white;

.sudoku-wrapper {
  display: flex;
  flex-direction: column;
  .sudoku-container {
    width: 284px;
    margin: 0 auto;
    .cell {
      width: 30px;
      height: 30px;
      position: relative;
      background: $background_color;
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      cursor: default;
      color: #333;
      font-weight: 600;
      background-color: #fff;

      &.mutable {
        background-color: #f4faff;
      }

      &.row-0,
      &.row-3,
      &.row-6 {
        border-top: 2px solid $border_color;
      }
      &.row-8 {
        border-bottom: 2px solid $border_color;
      }
      &.row-1,
      &.row-2,
      &.row-4,
      &.row-5,
      &.row-7,
      &.row-8 {
        border-top: 1px solid $border_color_two;
      }

      &.column-0,
      &.column-3,
      &.column-6 {
        border-left: 2px solid $border_color;
      }
      &.column-8 {
        border-right: 2px solid $border_color;
      }
      &.column-1,
      &.column-2,
      &.column-4,
      &.column-5,
      &.column-7,
      &.column-8 {
        border-left: 1px solid $border_color_two;
      }

      &.grayed-out {
        background-color: $greyed_out;
      }
      &.error {
        background-color: $error_color;
        &.mutable {
          background-color: #ffcccc;
        }
      }
      &.selected {
        -moz-box-shadow: inset 0 0 10px $inset_color;
        -webkit-box-shadow: inset 0 0 10px $inset_color;
        box-shadow: inset 0 0 10px $inset_color;
        .input-cell {
          // background-color: $selected_color;
          -moz-box-shadow: inset 0 0 10px $selected_color;
          -webkit-box-shadow: inset 0 0 10px $selected_color;
          box-shadow: inset 0 0 10px $selected_color;
        }
      }
    }
  }
}

.input-cell {
  width: 30px;
  height: 30px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #0360bb;
}

.sudoku-footer {
  .sudoku-numbers {
    width: 100%;
    display: inline-block;
    margin: 10px auto;
    > div {
      display: inline-block;
      border: 1px solid #0460bb;
      padding: 4px 10px;
      margin-left: 4px;
      border-radius: 3px;
      cursor: pointer;
      color: #0460bb;
      font-weight: 600;
      background-color: white;
      &:hover,
      &.selected {
        color: white;
        background-color: #0460bb;
      }
    }
  }

  .sudoku-errors {
    display: flex;
    align-items: center;
    span {
      width: 25px;
      height: 25px;
      display: inline-block;
      margin: 8px 6px 8px 6px;
      background-color: $error_color;
      border: 1px solid $border_color_two;
      &:first-child {
        background-color: #ffcccc;
      }
    }
  }
}

.game-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  margin: 0 auto 12px auto;
}
.sudoku-timer {
  border: 1px solid grey;
  padding: 5px;
  // margin: 10px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 6px;
}

.popover-styles {
  background-color: #97979791;
  .MuiPopover-paper {
    padding: 16px;
    width: 100%;
    max-width: 280px;
  }
}
.slider-with-marker {
  // margin: 60px 0 0 0;
  &.MuiSlider-root{
  padding: 13px 0 !important;
  }
  // background-image: url(/assets/images/difficult-2.png);
  // background-repeat: no-repeat;
  // background-position: 0 0;
  .MuiSlider-track {
    background-image: url(/assets/images/difficult-2.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 280px 6px;
    height: 6px;
  }
  .MuiSlider-rail{
    // height: 6px;
    background-color: #82828259;
    height: 2px;
    top: 15px;
  }
  .MuiSlider-thumb {
    margin-top: -4px;
    width: 14px;
    height: 14px;
  }
  .MuiSlider-mark{
    height: 6px;
    color: white;
    width: 1px;
  }
}



//small sudoku
.page-container.size-small{

}







.new-game-popover{
  text-align: center;
  // filter: blur(8px);
  // -webkit-filter: blur(8px);
  .MuiButtonBase-root {
    // margin-top: ;

  }
}









