.main-content {
  position: absolute;
  transition: ease-in-out 0.3s all;
  z-index: 1;
  background-color: #f9fafc;
  display: flex;
  // height: 100vh;
  // overflow: hidden;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  // overflow: hidden;
}

.main-content-back-one {
  background-color: red;
  height: 100vh;
  transition: ease-in-out 0.4s all;
  z-index: 1;
  margin-left: -60px;
  /* left: -31px; */
  position: relative;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100vw;
}

.main-content-back-two {
  background-color: blue;
  height: 100vh;
  transition: ease-in-out 0.5s all;
  z-index: 1;
  margin-left: -98px;
  position: relative;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100vw;
}

.menu-active {
  .main-content {
    transform: perspective(55px) rotateY(3deg) scale(0.5, 0.5);
    // overflow: hidden;
    // height: 100vh;
  }

  .main-content-back-one {
    transform: perspective(55px) rotateY(3deg) scale(0.49, 0.49);
  }

  .main-content-back-two {
    transform: perspective(55px) rotateY(3deg) scale(0.48, 0.48);
  }
}


@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .main-content,
    .main-content-back-one,
    .main-content-back-two {
      transition: ease-in-out 0.4s all;
    }
  }
}