.settings-wrapper {
    padding: 16px;
    min-width: 220px;
    > div {
        margin-top: 8px;
        &.settings-title {
            font-weight: 600;
        }
    }
}
.swatches-picker {
    height: auto !important;
    > div > div {
        box-shadow: none !important;
        > div {
            height: auto !important;
        }
    }
}

.settings-drawer {
    .MuiBackdrop-root {
        background-color: transparent;
    }
}


.page-container.hide-timer{
    .sudoku-timer{
        display: none;
    }
}

// size-${settings.size} 




.color-placeholder{
    display: flex;
    align-items: center;
  span{
      width: 40px;
      height: 24px;
      display: inline-block;
      border: 1px solid #f3f3f3;
      margin-right: 4px;
  }
}




// .sudoku-footer .sudoku-numbers > div {
//     display: inline-block;
//     border: 1px solid #0460bb;
//     padding: 1px 8px;
//     margin-left: 1px;
//     border-radius: 3px;
//     cursor: pointer;
//     color: #0460bb;
//     font-weight: 600;
// }