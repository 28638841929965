.fireworks-wrapper {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .fireworks-container{
    width: 100vw;
    height: 100vh;
    }
}
