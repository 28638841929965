.ReactModalPortal {
    .ReactModal__Overlay.ReactModal__Overlay--after-open{
        z-index: 3000;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.6) !important;
        .ReactModal__Content{
            .modal-container {
                display: block;
                z-index: 3001;
                background-color: white;
                color: black;
                padding: 10px;
                text-align: center;
                min-width: 300px;
                box-shadow: 0 2px 2px -1px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.1);

                .modal-title{
                    text-align: center;
                    font-weight: 700;
                    /* border-bottom: 1px solid #e1e2e1; */
                    /* border-bottom: 2px solid black; */
                    margin: 3px 15px;
                    padding: 10px 0 5px 0;
                    font-size: 20px;
                }
                p{
                    padding: 10px;
                }
            }
        }
    }
}



