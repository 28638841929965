.split-screen {
  > div {
    display: inline-block;
    width: 50%;
    &:first-child {
      text-align: right;
    }
    &:last-child {
      text-align: left;
    }
  }
}
