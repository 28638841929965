@import "../../styles/variables.scss";

.menu-component {
  display: inline-block;
  overflow: auto;
  position: relative;
  width: 40px;
  text-align: right;

  // @media (min-width: 768px) {
  //   display: none;
  // }
}
.menu-container {
  // background-color: white;
  position: fixed;
  width: 0;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  align-items: center;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  opacity: 0;
  right: 0;

  .inner {
    background-color: #fff;
    display: block;
    width: 100%;
    opacity: 0;
    top: 0;
    width: 100%;
    position: absolute;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    z-index: 201;
  }

  &.active {
    // visibility: visible;
    // overflow-y: scroll;
    // height: 100%;

    opacity: 1;
  width: 100%;
    .inner {
      opacity: 1;
      z-index: 201;
    }
  }

  .menu-links {
    margin: 24px 0;
    border-radius: 3px;
    // background-color: white;
    width: 24%;

    // > div{
    //   display: inline-block;
    //   position: relative;
    //   display: inline-block;
    //   > div{

    //     display: inline-block;
    //   }
    // }
    a {
      display: inline-block;
      padding: 10px 15px;
      // width: 100%;
      /* font-weight: 700; */
      letter-spacing: 1.2px;
      text-transform: uppercase;
      cursor: pointer;
      text-align: left;
      font-size: 15px;
    }
  }

  // @media (max-width: 600px) {
  //   &.active {
  //     z-index: 2;
  //     background-color: transparent;
  //     .menu-links {
  //       width: 100%;
  //     }
  //   }
  // }
}

.menu-list-old.hide {
  display: none;
}

/* menu-footer */
.menu-container ul.menu-footer {
  text-align: center;
  color: #848383;
  padding-top: 20px;
}
.menu-container ul.menu-footer li {
  display: inline-block;
}
@media (min-width: 1300px) {
  .user-name,
  html[dir="rtl"] .menu-container .user-name {
    max-width: 1200px;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .menu-container ul.menu-footer {
    text-align: left;
    padding-top: 0;
  }
  .menu-container ul.menu-footer li {
    display: block;
    padding: 10px 10px 5px 15px;
    border-bottom: 1px solid #d6d6d6;
  }
  .menu-container ul.menu-footer li.hide {
    display: none;
  }
}

/* hamburger conatiner */
.hamburger-container {
  display: inline-block;
  cursor: pointer;
  z-index: 2001;
  position: relative;
  margin-top: 3px;
  right: 15px;
  position: absolute;
}
.hamburger-container .bar1,
.hamburger-container .bar2,
.hamburger-container .bar3 {
  width: 28px;
  height: 4px;
  // background-color: #3a3a3a;
  margin: 5px 0;
  transition: 0.4s;
  border-radius: 5px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

@media (hover: hover) {
  .hamburger-container:hover .bar1,
  .hamburger-container:hover .bar2,
  .hamburger-container:hover .bar3 {
    background-color: $base-1;
  }
}

.hamburger-container.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}
.hamburger-container.change .bar2 {
  opacity: 0;
}
.hamburger-container.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -4px);
  transform: rotate(45deg) translate(-6px, -4px);
}

.menu-list {
  margin: 0;
}

.menu-container ul.menu-buttons {
  background-color: #ffd3ff;
  text-align: right;
  width: 30%;
  padding-top: 70px;
}

.menu-container ul.menu-shadow {
  background-color: #ffd3ff;
  text-align: right;
  width: 10%;
}

.menu-container ul.menu-links li a {
  text-shadow: -9px 5px 0px rgba(253, 211, 255, 0.25);
}






  @media (max-width: 600px) {
    .menu-container.active{
      width: auto;
      z-index: 2;
      .menu-links {
        width: auto;
        >div{
          background-color: white;
        }
    }
  }
    


  }