//variables

$grey: #9e9e9e;
$light-grey: #eee;
$black: #333;
$hover-blue: #3a76ba;
$blue: rgb(54, 139, 214);
$light-blue: #77bfe7;

$base-1: purple;
// $base-1: rebeccapurple;
// $base-1: #663399;

$base-light: #fbf9fb;
$base-light-2: #fbfbfb;
$base-dark: #939393;
$cancel: #f97070;

@mixin transition_image() {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
@mixin transition() {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

@mixin transition-4() {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

/* shake search box on no search result */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
  }
  60% {
    -webkit-transform: translateX(-10px);
  }
  80% {
    -webkit-transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
