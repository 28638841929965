
.container,
.content {
  max-width: 1366px;
  margin: 0 auto;
}
@media (max-width: 1440px) {
  .container,
  .content {
    padding: 0px;
  }
}
@media (max-width: 1440px) {
  .container,
  .content {
    padding: 0 10px;
  }
}