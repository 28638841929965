.rokos-adventure{
    width: 100%;
    // width: 960px; height: 600px; padding: 0px; margin: 0px; border: 0px; position: relative; background: rgb(35, 31, 32);
    // max-width: 700px;
    // margin: 0 auto;
    // .section{
    //     margin-top: 40px;
    //     P{
    //         margin-top: 10px;
    //     }
    // }
    iframe{
        width: 960px;
        height: 800px;
        padding: 0px;
        margin: 0px;
        border: 0px;
        position: relative;


        // width: 960px; height: 600px; padding: 0px; margin: 0px; border: 0px; position: relative; background: rgb(35, 31, 32);
    }
}